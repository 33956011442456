import Head from "next/head";
import type { AppProps } from "next/app";

import { GlobalStyles } from "@contentful/f36-components";
import "@contentful/forma-36-react-components/dist/styles.css";
import "../styles/index.css";

const MyApp = ({ Component, pageProps }: AppProps) => {
	return (
		<>
			<Head>
				<title>Evernest Contentful Extensions</title>
			</Head>
			<GlobalStyles />
			<Component {...pageProps} />
		</>
	);
};

export default MyApp;
